import React, { Component } from "react";
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';

import { h1 } from 'components/Theme/Copy';
import { Container, Card  } from 'components/Theme/Grid';

const HeroContainer = styled(Container)`
  
`;

const HeroCard = styled(Card)`
  padding:10rem;
  position:relative;
  background-color:${p => p.theme.cardBgColorDark};
  color:#fff;
  ${media.lessThan("tablet")`
    padding:5rem 0rem;
  `}
`;

const HeroCopy = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  height:100%;
  ${media.lessThan("tablet")`
    align-items:flex-start;
  `}
`;


const HeroHeadline = styled(h1)`
  color:#fff;
  font-weight:100;
  font-size:6rem;
  margin:0;
  letter-spacing:1px;
  ${media.lessThan("tablet")`
    margin:0;
  `}
`;

class Hero extends Component {

    render() {
        const { heroHeadline } = this.props.data;
        return ( 
          <HeroContainer>
            <HeroCard neumorphic>
              <HeroCopy>
                <HeroHeadline>{ heroHeadline }</HeroHeadline>
              </HeroCopy>
            </HeroCard>
          </HeroContainer>
        )
    }
}



export default Hero