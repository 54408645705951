import React, { Component } from "react";
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { Link } from "gatsby"
import { h1 } from 'components/Theme/Copy';
import { Card  } from 'components/Theme/Grid';

const AddressCard = styled(Card)`
  flex-direction:column;
  justify-content: center;
  align-items: center;
  background-color:${p => p.theme.cardBgColor};
  

  ${media.lessThan("tablet")`
    width:100%;
    padding:8rem 0;
  `}
`;

const Header = styled(h1)`
  letter-spacing:0.2em;
  text-transform: uppercase;
  font-size:2rem;
  font-weight:bold;
  color:${p => p.theme.brandPrimaryLight};
  margin:2rem 0 1rem 0;
  padding:0;
  ${media.lessThan("tablet")`
    margin:2rem 0 1rem 0;
    padding:0;
    font-size:1rem;
  `}
`;

const Item = styled(Link)`
  ${p => p.theme.typographyBody};
  font-size:1.6rem;
  margin:0;
  padding:0;
  display:block;
  text-transform:uppercase;
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    font-size:1.4rem;
    text-align:center;
  `}
`;

const ItemExternal = styled.a`
  ${p => p.theme.typographyBody};
  font-size:1.6rem;
  margin:0;
  padding:0;
  display:block;
  color:${p => p.theme.cardTextColor};
  ${media.lessThan("tablet")`
    font-size:1.4rem;
  `}
`;

class Address extends Component {

    render() {
        return ( 
          <AddressCard neumorphic size={2} sm={1}>
            <div>
              <div>
                <Header>Address</Header>
                <Item>20281 Harvill Ave</Item>
                <Item>Perris, CA 92570</Item>
              </div>
            
              <div>
                <Header>Email us</Header>
                <ItemExternal href="mailto:help@cookwithcarbon.com" target="_blank">help@cookwithcarbon.com</ItemExternal>
              </div>

              <div>
                <Header>Chat with us</Header>
                <Item>M-F 9AM - 5PM PST</Item>
              </div>
            </div>
          </AddressCard>
        )
    }
}


export default Address