import React, { Component } from "react";
import { graphql } from 'gatsby'
import Layout from "components/layout";
import SEO from "components/seo";
import _ from 'lodash';
import Hero from 'components/Page/Contact/Hero';
import Form from 'components/Page/Contact/Form';
import Address from 'components/Page/Contact/Address';
import { Container } from 'components/Theme/Grid';

class Contact extends Component {

    render() {
        const listPage = this.props.data.contentfulContact
        const heroData = _.pickBy(listPage, (v, k) => { return /^hero/.test(k) })
        return ( 
          <Layout >
            <SEO title = 'Contact us'/>
            <Hero data = { heroData }/>
            <Container>
              <Form />
              <Address />
            </Container>
          </Layout >
        )
    }
}

export default Contact;

// language=GraphQL
export const pageQuery = graphql `
  query ContactQuery {
    contentfulContact {
      heroAsset {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      heroHeadline
    }
  }
`;