import React, { Component } from "react";
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import FormTag from 'components/Page/Forms/Form';
import TextField from 'components/Page/Forms/TextField';
import TextAreaField from 'components/Page/Forms/TextAreaField';
import Button from 'components/Theme/Button'
import { h1 } from 'components/Theme/Copy';
import { Card  } from 'components/Theme/Grid';

const FormCard = styled(Card)`
  padding:5rem;
  flex-direction:column;
  background-color:${p => p.theme.cardBgColor};
  ${media.lessThan("tablet")`7
    padding-top:0;
  `}
`;

const Subheader = styled(h1)`
  font-weight:bold;
  letter-spacing:0.2em;
  text-transform: uppercase;
  font-size:2rem;
  margin:2rem 0 0 0;
  width:100%;
  display:block;
  color:${p => p.theme.brandPrimaryLight};
  ${media.lessThan("tablet")`7
    margin:2rem 0 0 0;
    font-size:1rem;
  `}
`;


const InnerFormContainer = styled.div`
  display:flex;
  flex-direction: ${p => p.direction === 'Vertical' && !p.hasDoubleVerticalColumns ? 'column' : 'row'};;
  justify-content:center;
  flex-wrap: wrap;
  margin-top:2rem;
  
  ${media.lessThan("tablet")`
    flex-direction:column;
    align-items:center;
    margin-top:2rem;
  `}
`;

const SendButton = styled(Button)`
  margin-top:2rem;
`;


class Form extends Component {

    render() {
        return ( 
          <FormCard neumorphic size={2} sm={1}>
            <Subheader>Questions, comments, concerns?</Subheader>
            <FormTag name="Contact" successPage="/contact">
              <InnerFormContainer>
                <TextField type="text"
                    autocomplete="off"
                    autocorrect="off"
                    spellcheck="false"
                    autocapitalize="none"
                    name="firstName"
                    required="required"
                    placeholder="First Name" />
                <TextField type="text"
                    autocomplete="off"
                    autocorrect="off"
                    spellcheck="false"
                    autocapitalize="none"
                    name="lastName"
                    required="false"
                    placeholder="Last Name" />
                <TextField type="email"
                  autocomplete="off"
                  autocorrect="off"
                  spellcheck="false"
                  autocapitalize="none"
                  name="email"
                  required="required"
                  placeholder="Email Address" />
                <TextAreaField
                  type="textarea"
                  name="message"
                  placeholder="Message"
                  />
                <SendButton>Send</SendButton>
                </InnerFormContainer>
              </FormTag>
          </FormCard>
        )
    }
}



export default Form